import React from 'react'
import PropTypes from 'prop-types'
import Layout from '../components/Layout'
import Book from "../components/Book";
import useProjectIndex from "../hooks/use-project-index";
import { graphql } from "gatsby";
import { HTMLContent } from "../components/Content";

const ProjectIndexTemplate = (
  {
    data: {
      markdownRemark: {
        html,
        frontmatter: {kind}
      }
    } = {data: {markdownRemark: {frontmatter: {}}}}
  }) => {
  const items = useProjectIndex(kind);

  return (
    <Layout>
      {html && <HTMLContent content={html} className="content is-size-5"/>}

      <div className="columns is-multiline is-variable is-2-mobile is-4-tablet is-6-desktop is-8-widescreen">
        {items.map(({slug, title, book_image: image}) => (
          <div className="column is-half-tablet is-one-third-desktop mb-4" key={slug}>
            <Book title={title} slug={slug} image={image}/>
          </div>
        ))}
      </div>
    </Layout>
  );
};

ProjectIndexTemplate.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      html: PropTypes.string,
      frontmatter: PropTypes.shape({
        kind: PropTypes.string
      })
    })
  })
};

export default ProjectIndexTemplate

export const collectionQuery = graphql`
  query CollectionQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        kind
      }
      html
    }
  }
`;
