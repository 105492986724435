import { graphql, useStaticQuery } from "gatsby"

const processProjectData = (rawData, kindFilter) => (
  rawData.allMarkdownRemark.edges
    .filter(({node: {frontmatter: {kind, book_image}}}) => kind === kindFilter && book_image !== null)
    .map((e) => ({
      title: e.node.frontmatter.title,
      book_image: e.node.frontmatter.book_image,
      slug: e.node.fields.slug
    }))
);

/* Abstracts away a lot of horrible graphql and produces a nice filtered repacked array of project objects */
const useProjectIndex = (kindFilter) => {
  const rawData = useStaticQuery(
    graphql`
      query AllPlayableProjects {
        allMarkdownRemark(sort: {fields: frontmatter___position, order: [ASC]}) {
          edges {
            node {
              frontmatter {
                title
                kind
                book_image {
                  childImageSharp {
                    fluid(maxWidth: 600, quality: 90) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              fields {
                slug
              }
            }
          }
        }
      }
    `);
  return processProjectData(rawData, kindFilter)
};

export default useProjectIndex
