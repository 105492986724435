import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'
import SmartLink from "./SmartLink";

const StyledLink = styled(SmartLink)`
    & > div {
      opacity: 0.8!important;
      transition: opacity .5s;
    }
    &:hover, &:focus {
      & > div {
        opacity: 0.99!important;
      }
    }
`;

export const Book = ({image, title, externalLink, slug, imageClassName, withTitle}) => (
  <>
    <div className="card">
      <div className="card-image">
        <StyledLink path={slug} link={externalLink}>
          <BackgroundImage
            backgroundColor="#ffffff"
            className={`image is-3by4 ${imageClassName}`}
            fluid={image.childImageSharp.fluid}
            alt={title}
          />
        </StyledLink>
      </div>
    </div>
    {withTitle && title && (<h3 className="is-size-3 mt-4"><SmartLink path={slug} link={externalLink}>{title}</SmartLink></h3>)}
  </>
);

Book.propTypes = {
  title: PropTypes.string,
  link: PropTypes.string,
  image: PropTypes.shape({
    childImageSharp: PropTypes.shape({
      fluid: PropTypes.object
    })
  }),
  imageClassName: PropTypes.string,
};

export default Book;
