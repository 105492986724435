import React from "react";
import { Link } from 'gatsby'

const SmartLink = ({path, link, children}) => {
  if (link) {
    return <a href={link} target="_blank" rel="noreferrer">{children}</a>
  }

  return (<Link to={path}>{children}</Link>)
};

export default SmartLink

